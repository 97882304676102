<template>
  <v-container fluid class="ma-4 pr-10">
    <v-row>
      <v-col>
        <h1 class="secondary--text">Movimentação Cadastral</h1>
        <h2 class="secondary--text font-weight-regular">
          Inclusão de dependente eventual
        </h2>
      </v-col>
    </v-row>
    <v-form ref="formRegister" v-model="isValidForm" lazy-validation>
      <v-row class="mt-10 align-center" lazy-validation>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Grupo Financeiro</label>
          <v-autocomplete
            v-model="search.financialGroupsIds"
            :items="financialGroups"
            item-text="name"
            item-value="id"
            :loading="loadingFinancialGroups"
            class="mt-2"
            placeholder="Selecione"
            outlined
            color="textPrimary"
            :disabled="search.insuranceCarrierId ? true : false"
            @input="verifyFieldsFilters()"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Operadora</label>
          <v-autocomplete
            v-model="search.insuranceCarrierId"
            :items="insuranceCarriers"
            item-text="xipp_commercial_name"
            item-value="id"
            :loading="loadingInsuranceCarriers"
            class="mt-2"
            placeholder="Selecione"
            outlined
            color="textPrimary"
            :disabled="search.financialGroupsIds ? true : false"
            @input="verifyFieldsFilters()"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Contrato</label>
          <v-autocomplete
            v-model="search.contractsIds"
            :items="contracts"
            item-value="id"
            :loading="loadingContracts"
            class="mt-2"
            placeholder="Selecione"
            outlined
            color="textPrimary"
            :disabled="search.insuranceCarrierId ? true : false"
            @input="verifyFieldsFilters(); verifyFreeMovement()"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="11" md="3" lg="3" xl="2">
          <v-btn
            class="white--text"
            x-large
            block
            color="textPrimary"
            :disabled="isDisableFreeMovement"
            @click="onClickInsertDependent('freeMovement')"
          >
            Movimentação livre
          </v-btn>
        </v-col>
        <v-col class="pt-0 pl-0 pb-6" cols="12" xs="12" sm="1" md="3" lg="2">
          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                dense
              >
                fas fa-info-circle
              </v-icon>
            </template>
            <span>Movimentação livre: escolha um contrato que permita movimentação livre</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="align-center" lazy-validation>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">CPF do titular</label>
          <v-text-field
            v-model="search.physicalPersonCPF"
            class="mt-2"
            placeholder="Informe o número"
            v-mask="'###.###.###-##'"
            :rules="search.physicalPersonCPF ? [rule.cpf] : []"
            outlined
            color="textPrimary"
            @input="verifyFieldsFilters()"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Nome do titular</label>
          <v-text-field
            v-model.trim="search.physicalPersonName"
            :rules="search.physicalPersonName ? [rule.name, rule.validateIsAlphanumeric] : []"
            class="mt-2"
            placeholder="Informe o nome"
            maxlength="70"
            outlined
            color="textPrimary"
            @input="verifyFieldsFilters();"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Matrícula</label>
          <v-text-field
            maxlength="50"
            v-model="search.registration"
            class="mt-2"
            placeholder="Informe a matrícula"
            outlined
            color="textPrimary"
            @input="verifyFieldsFilters()"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="2">
          <v-btn
            class="white--text"
            x-large
            block
            color="textPrimary"
            :disabled="isDisableFieldFilters"
            @click.prevent="filter('filter')"
          >
            Localizar
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <v-btn color="textPrimary" x-large text @click="clearFields()">
            Limpar campos
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12">
        <v-data-table
          class="elevation-1"
          hide-default-footer
          show-expand
          single-expand
          expanded.sync
          :sort-desc="true"
          :calculate-widths="true"
          :headers="headers"
          :items="results.content"
          item-key="contractPlanId"
          :loading="isLoading"
          :page.sync="page"
          :items-per-page="10"
        >
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
            <v-icon
              v-if="item.dependents && item.dependents.length"
              :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
              @click.stop="expand(!isExpanded)">
              $expand
            </v-icon>
          </template>
          <template v-slot:[`item.holder`]="{ item }">
            {{ item.physicalPersonName ? formatter.formatToTitleCase(item.physicalPersonName) : "-" }}
          </template>
          <template v-slot:[`item.financialGroupId`]="{ item }">
            {{ formatter.formatFinancialGroup(item.financialGroupId, financialGroups) }}
          </template>
          <template v-slot:[`item.contract`]="{ item }">
            {{ item ? contructorContractName(item) : '' }}
          </template>
          <template v-slot:[`item.subContract`]="{ item }">
            {{ item ? contructorSubContractName(item) : '-' }}
          </template>
          <template v-slot:[`item.plan`]="{ item }">
            {{ item.planDescription ? item.planProductCode + " - " + item.planDescription : item.planProductCode + " - " + item.planName }}
          </template>
          <template v-slot:[`item.startDate`]="{ item }">
            {{ formatter.formatDate(item.startDate) }}
          </template>
          <template v-slot:[`item.dependents`]="{ item }">
            {{ item.dependents ? item.dependents.length : "-" }}
          </template>
          <template v-slot:[`item.attachments`]="{ item }">
            <v-menu open-on-hover bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                  <v-icon color="textPrimary" size="20"
                    >fas fa-ellipsis-v</v-icon
                  >
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="onClickInsertDependent(item)">
                  <v-list-item-icon class="mr-2">
                    <v-icon size="20" color="textPrimary"
                      >fas fa-user-plus</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-content class="mt-1">
                    <v-list-item-title>Incluir dependente</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="mx-0 px-0">
              <v-row class="pa-0 ma-0 white">
                <v-col class="px-0 py-0" cols="12">
                  <v-data-table
                    loading-text="Carregando..."
                    dense
                    :headers="headersDependents"
                    :items="item.dependents"
                    hide-default-footer
                    class="elevation-1 px-0"
                  >
                    <template v-slot:no-data>
                      <span>Este beneficiário não possui dependente.</span>
                    </template>
                    <template v-slot:[`header.holder`]>
                      <span>{{ "Dependente" }}</span>
                    </template>
                    <template v-slot:[`item.holder`]="{ item }">
                      {{ item.physicalPersonName ? formatter.formatToTitleCase(item.physicalPersonName) : "-" }}
                    </template>
                    <template v-slot:[`item.financialGroupId`]="{ item }">
                      {{ formatter.formatFinancialGroup(item.financialGroupId, financialGroups) }}
                    </template>
                    <template v-slot:[`item.contract`]="{ item }">
                      {{ item ? contructorContractName(item) : '' }}
                    </template>
                    <template v-slot:[`item.subContract`]="{ item }">
                      {{ item ? contructorSubContractName(item) : '-' }}
                    </template>
                    <template v-slot:[`item.plan`]="{ item }">
                      {{ item.planDescription ? item.planProductCode + " - " + item.planDescription : item.planProductCode + " - " + item.planName }}
                    </template>
                    <template v-slot:[`item.startDate`]="{ item }">
                      {{ formatter.formatDate(item.startDate) }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
        <div class="float-right pt-2">
          <v-pagination
            v-model="page"
            color="textPrimary"
            :length="totalPages"
            :total-visible="9"
            :disabled="isLoading"
            @input="filter('pagination')"
          />
        </div>
      </v-col>
    </v-row>
    <v-footer class="my-10">
      <v-row class="d-flex justify-end">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            x-large
            block
            outlined
            color="textPrimary"
            @click="onClickCancel()"
          >
            Voltar
          </v-btn>
        </v-col>

        <v-snackbar
          v-model="snackbar.show"
          timeout="10000"
          top
          elevation="24"
          :color="snackbar.type"
        >
          <div class="text-center">{{ snackbar.text }}</div>
          <template v-slot:action="{ attrs }">
            <v-icon
              text
              dark
              v-bind="attrs"
              @click="snackbar.show = false"
            >
              close
            </v-icon>
          </template>
        </v-snackbar>
      </v-row>
    </v-footer>

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="120"
      >
        <div>
          Carregando...
        </div>
      </v-progress-circular>
    </v-overlay>

    <DynamicDialogInformation ref="DynamicDialogInformation" :widthModalValue="widthModal" :heightCardValue="heightCard">
      <v-row class="mb-3" justify="center" slot="textInfo" style="word-break: break-word">
        <v-col cols="12" align="center">
          <label class="label primary--text font-italic" style="word-break: break-word">“Ops! Algo deu errado. Isso pode ter acontecido por você ter selecionado um grande volume de informações. Tente refinar os filtros e repetir a operação”</label>
        </v-col>
      </v-row>
    </DynamicDialogInformation>

  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import BeneficiaryContractedPlanService from '@/services-http/contract/BeneficiaryContractedPlanService';
import DynamicDialogInformation from '@/components/DynamicDialogInformation/DynamicDialogInformation.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import lodash from 'lodash';
import ContractService from '@/services-http/contract/ContractService';

export default {
  name: 'ManualMovementDependentInclusionSearchHolders',
  components: { DynamicDialogInformation },
  data: () => ({
    isValidForm: true,
    overlay: false,
    headers: [
      {
        text: 'Titular',
        value: 'holder',
        align: 'center',
        sortable: false,
        class: 'text--truncate',
      },
      {
        text: 'Grupo Financeiro',
        value: 'financialGroupId',
        align: 'center',
        sortable: false,
        class: 'text--truncate',
      },
      {
        text: 'Contrato',
        value: 'contract',
        align: 'center',
        sortable: false,
        class: 'text--truncate',
      },
      {
        text: 'Sub-Contrato',
        value: 'subContract',
        align: 'center',
        sortable: false,
        class: 'text--truncate',
      },
      {
        text: 'Plano',
        value: 'plan',
        align: 'center',
        sortable: false,
        class: 'text--truncate',
      },
      {
        text: 'Início de Vigência',
        value: 'startDate',
        align: 'center',
        sortable: false,
        class: 'text--truncate',
      },
      {
        text: 'Dependentes',
        value: 'dependents',
        align: 'center',
        sortable: false,
        class: 'text--truncate',
      },
      {
        text: 'Ações',
        value: 'attachments',
        align: 'center',
        sortable: false,
        class: 'text--truncate',
      },
      {
        text: ' ',
        align: 'right',
        value: 'data-table-expand',
        class: 'text--truncate',
      },
    ],

    headersDependents: [
      {
        text: 'Titular',
        value: 'holder',
        align: 'center',
        class: 'text--truncate',
      },
      {
        text: 'Grupo Financeiro',
        value: 'financialGroupId',
        align: 'center',
        class: 'text--truncate',
      },
      {
        text: 'Contrato',
        value: 'contract',
        align: 'center',
        class: 'text--truncate',
      },
      {
        text: 'Sub-Contrato',
        value: 'subContract',
        align: 'center',
        class: 'text--truncate',
      },
      {
        text: 'Plano',
        value: 'plan',
        align: 'center',
        class: 'text--truncate',
      },
      {
        text: 'Início de Vigência',
        value: 'startDate',
        align: 'center',
        class: 'text--truncate',
      },
      {
        text: ' ',
        align: 'right',
        value: 'data-table-expand',
        class: 'text--truncate',
      },
    ],

    search: {
      financialGroupsIds: null,
      insuranceCarrierId: null,
      contractsIds: null,
      registration: null,
      physicalPersonCPF: null,
      physicalPersonName: null,
    },
    isLoading: false,
    results: [],
    financialGroups: [],
    contracts: [],
    insuranceCarriers: [],
    page: 1,
    totalPages: 0,
    isRHProtegido: false,
    insuranceCarrierId: '',
    loadingFinancialGroups: false,
    loadingInsuranceCarriers: false,
    loadingContracts: false,
    isDisableFieldFilters: true,
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
    isDisableFreeMovement: true,
    widthModal: 800,
    heightCard: 600,
  }),

  async mounted() {
    this.clearSessionStorage();
    this.getInsuranceCarriers();
    await this.getFinancialGroups();
    if (this.financialGroups && this.financialGroups.length === 1) {
      this.search.financialGroupsIds = this.financialGroups[0].id;
      this.verifyFieldsFilters();
    }
  },

  mixins: [
    VerifyRoutesMixin,
    FinancialGroupsMixin,
    ContractsMixin,
    InsuranceCarriersMixin,
  ],

  watch: {
    'search.financialGroupsIds': {
      deep: true,
      handler(val) {
        const financialGroupIds = lodash.cloneDeep(val);
        if (financialGroupIds) {
          this.getContractsByFinancialGroupId(financialGroupIds);
        }
      },
    },
  },

  methods: {
    getPlanConcatenation(plan) {
      let planConcatenation = '';
      if (plan.productCode) {
        planConcatenation = plan.productCode;
      }

      if (planConcatenation) {
        planConcatenation += ' - ';
      }

      if (plan.description) {
        planConcatenation += plan.description;
      } else {
        planConcatenation += plan.name;
      }
      return planConcatenation;
    },
    filter(type) {
      if (this.$refs.formRegister.validate()) {
        if (this.search.financialGroupsIds !== null || this.search.insuranceCarrierId !== null || this.search.contractsIds !== null || this.search.registration !== null || this.search.physicalPersonCPF !== null || this.search.physicalPersonName !== null) {
          if (type === 'filter') this.page = 1;
          const data = this.verifyParams();
          this.loadBeneficiary(data);
        }
      } else {
        this.customizeSnackbarMessage('error', 'Preencha corretamente para prosseguir');
      }
    },
    verifyParams() {
      const cpfUnmasked = this.search.physicalPersonCPF ? this.formatter.removeNonDigit(this.search.physicalPersonCPF) : null;
      const isActive = true;
      const page = this.page - 1;
      const size = 10;

      const params = new URLSearchParams();
      if (this.search.financialGroupsIds) {
        params.append('financialGroupsIds', this.search.financialGroupsIds);
      }
      if (this.search.insuranceCarrierId) {
        params.append('insuranceCarrierId', this.search.insuranceCarrierId);
      }
      if (this.search.contractsIds) {
        params.append('contractsIds', this.search.contractsIds);
      }
      if (this.search.registration) {
        params.append('registration', this.search.registration);
      }
      if (this.search.physicalPersonCPF) {
        params.append('physicalPersonCPF', cpfUnmasked);
      }
      if (this.search.physicalPersonName) {
        params.append('physicalPersonName', this.search.physicalPersonName);
      }

      if (this.isRHProtegido) {
        params.append('allowMovementByProtectedHR', 'true');
      }

      params.append('isActive', isActive);
      params.append('page', page);
      params.append('size', size);

      return params;
    },
    isEmptyFilters() {
      return !(this.search.financialGroupsIds || this.search.insuranceCarrierId || this.search.contractsIds || this.search.registration || this.search.physicalPersonCPF || this.search.physicalPersonName);
    },
    async onClickInsertDependent(item) {
      await this.requestInsuranceCarrier(item && item.contractId ? item.contractId : this.search.contractsIds);
      const queryString = {
        isRHProtegido: this.isRHProtegido,
        movementType: 'INSERT_DEPENDENT',
        beneficiaryType: 'DEPENDENT',
        insuranceCarrierId: this.insuranceCarrierId,
      };

      let contractedPlanInfo = item;
      if (item === 'freeMovement') {
        contractedPlanInfo = {};
        contractedPlanInfo.financialGroupId = this.search.financialGroupsIds;
        contractedPlanInfo.contractId = this.search.contractsIds;
        queryString.freeMovement = true;
      }
      sessionStorage.setItem('contractedPlanInfo', JSON.stringify(contractedPlanInfo));

      this.redirectRouter('DependentInclusionSave', queryString);
    },
    async loadBeneficiary(params) {
      this.isLoading = true;
      await this.beneficiaryContractedPlanService.FindAllByFilters(params).then((response) => {
        if (response && response.data) {
          this.isLoading = false;
          this.totalPages = response.data.totalPages;
          this.results = response.data;
        }
      }).catch(() => {
        this.isLoading = false;
        this.$refs.DynamicDialogInformation.open();
      });
    },
    onClickCancel() {
      const name = this.isRHProtegido ? 'HomeRHProtegido' : 'Movement';
      this.redirectRouter(name, { isRHProtegido: this.isRHProtegido });
    },
    clearFields() {
      this.$refs.formRegister.reset();
      this.contracts = [];
    },
    verifyFieldsFilters() {
      if (this.search.financialGroupsIds || this.search.insuranceCarrierId || this.search.contractsIds || this.search.registration || this.search.physicalPersonCPF || this.search.physicalPersonName) {
        this.isDisableFieldFilters = false;
      } else {
        this.isDisableFieldFilters = true;
      }
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
    contructorContractName(item) {
      let text = '';
      if (item) {
        text = `${item.contractBenefitCarrier ? item.contractBenefitCarrier : '-'} - ${item.contractName ? item.contractName : '-'}`;
      } else {
        text = '-';
      }
      return text;
    },
    contructorSubContractName(item) {
      let text = '';
      if (item) {
        text = `${item.subcontractNumber ? item.subcontractNumber : '-'} - ${item.subcontractBusinessName ? item.subcontractBusinessName : '-'}`;
      } else {
        text = '-';
      }
      return text;
    },
    async requestInsuranceCarrier(contract) {
      if (contract) {
        this.overlay = true;
        await this.contractService.FindById(contract).then(async (response) => {
          this.insuranceCarrierId = response.data.carrier_id.id;
          this.overlay = false;
        }).catch(() => {
          this.overlay = false;
        });
      }
    },
    verifyFreeMovement() {
      this.isDisableFreeMovement = this.contracts.filter((contract) => contract.id === this.search.contractsIds).some((isFreeMovement) => !isFreeMovement.allow_free_movement);
    },
    clearSessionStorage() {
      const keyToken = '@auth/token';
      const valueToken = sessionStorage.getItem(keyToken);
      const keyUser = '@auth/user';
      const valueUser = sessionStorage.getItem(keyUser);
      sessionStorage.clear();
      sessionStorage.setItem(keyToken, valueToken);
      sessionStorage.setItem(keyUser, valueUser);
    },
  },
  async created() {
    this.rule = new Rules();
    this.formatter = new Formatters();
    this.beneficiaryContractedPlanService = new BeneficiaryContractedPlanService();
    this.contractService = new ContractService();
  },
};
</script>
